<template>
  <div>
    <base-material-card
      color="deep-purple darken-2"
      class="px-4 py-2"
    >
      <template v-slot:heading>
        <div
          class="display-2 font-weight-bold"
        >
          {{ componentTitle }}
        </div>
      </template>
      <v-card-text>
        <v-data-table
          :headers="buyBackHeaders"
          :items="buyBackProducts"
          item-key="conversionId"
          hide-default-footer
          single-select
          class="elevation-1"
        >
          <template
            v-if="showSubTitle"
            v-slot:top
          >
            <div
              class="pb-2"
            >
              {{ $t('subTitleComponentBuyBackDetails') }}
            </div>
          </template>
          <template v-slot:item.buybackQuantity="{ item }">
            <span
              class="font-weight-bold"
            >
              {{ item.buybackQuantity }} {{ item.unitText }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
    <error-dialog
      dialog-type="error"
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>
<script>
  import i18n from '@/i18n'
  import { mapFields } from 'vuex-map-fields'
  import productAPI from '@/services/productServices'
  function initialState () {
    return {
      showErrorDialog: false,
      errorMessage: '',
      modelProcessing: false,
      buyBackHeaders: [
        {
          text: i18n.t('col-buyback-ProductGroup'),
          align: 'start',
          value: 'buybackProductGroupName',
          sortable: false,
          class: 'deep-purple lighten-4 display-1 font-weight-bold',
        },
        {
          text: i18n.t('col-buyback-Quantity'),
          align: 'end',
          value: 'buybackQuantity',
          sortable: false,
          class: 'deep-purple lighten-4 display-1 font-weight-bold',
          width: '25%',
        },
      ],
      buyBackProducts: [],
    }
  }
  export default {
    name: 'BuyBackComponent',
    components: {
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
    },
    props: {
      dataType: {
        type: Number,
        default: 1,
      },
      listingId: {
        type: Number,
        default: 0,
      },
      partnerId: {
        type: Number,
        default: 0,
      },
      quantity: {
        type: Number,
        default: 0,
      },
    },
    data: function () {
      return initialState()
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
      }),
      componentTitle: function () {
        let title = ''
        switch (this.dataType) {
          case 1:
            // Send Scrap
            title = this.$t('titleComponentBuyBackDetails')
            break
          case 2:
            // Receive Scrap
            title = this.$t('titleComponentBuyBackInfo')
            break
        }
        return title
      },
      showSubTitle: function () {
        let showTitle = false
        switch (this.dataType) {
          case 1:
            // Send Scrap
            showTitle = true
            break
          case 2:
            // Receive Scrap
            break
        }
        return showTitle
      },
    },
    mounted: function () {
      this.modelProcessing = true
      productAPI.getProductListingConversion(this.listingId, this.partnerId)
        .then(response => {
          this.buyBackProducts = []
          for (const item of response.data) {
            item.buybackQuantity = (this.quantity * item.buybackConversionFactor * item.buyBackPercentage) / 100
            this.buyBackProducts.push(item)
          }
          this.modelProcessing = false
        })
        .catch(error => {
          this.showErrorDialog = true
          this.errorMessage = error.message
          this.modelProcessing = false
        })
    },
  }
</script>
